/* General reset and base styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    width: 100%;
    height: 100%;
    font-family: 'Arial', sans-serif;
    background-color: white; /* Background color set to white */
}

.button {
    background-color: #007bff; /* Example blue color for contrast */
    color: white;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
}

/* Header styles */
.header {
    background-image: url('./images/background-image.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Fix background during scroll */
    padding: 4rem 5rem; /* Increased top and bottom padding */
    position: relative; /* For overlay positioning */
    color: white;
    display: flex; /* Use flexbox for vertical alignment */
    justify-content: space-between; /* Space out nav items */
    align-items: center; /* Center items vertically */
}

.header a {
    text-decoration: none; /* Removes the underline from the link */
    color: inherit; /* This will make the link use the current text color */
  }

  .header a:hover {
    text-decoration: underline; /* Optional: add underline on hover for better UX */
  }



/* Semi-transparent overlay */
.header::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(42, 63, 95, 0.6); /* Adjust color and opacity to your liking */
    mix-blend-mode: multiply; /* Blend the overlay with the background image */
    z-index: -1; /* Place overlay behind the content */
}

/* Align nav items */
.header nav {
    position: relative; /* To stay above the overlay */
    z-index: 1;
}

.header nav a {
    color: #e0e8f9; /* Lighter color for readability */
    text-decoration: none;
    margin: 0 1rem; /* Adjust side margins as needed */
    transition: color 0.3s;
}

.header nav a:hover {
    color: #ffffff; /* Brighter color on hover for interactivity */
}

/* Hero section styles */
.hero {
    background: linear-gradient(135deg, rgba(42, 63, 95, 0.9) 0%, rgba(71, 85, 105, 0.9) 100%), url('./images/background-image.jpg') no-repeat center center / cover;
    color: #ffffff; /* Kept white for clarity and contrast */
    text-align: center;
    padding: 4rem 1rem;
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1rem;
    margin-bottom: 2rem;
}



/* Service section styles */

.services-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns */
    grid-gap: 2.5%; /* Adjust the gap between the cards as needed */
    padding: 10%; /* Padding around the container */
    justify-content: center;
    align-items: start; /* Align the start of each card */
    margin-top: -8rem
  }
  

.service-section {
    border: 1px solid #000; /* Example border */
    padding: 1rem; /* Example padding */
    margin: 1rem 0; /* Example margin */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 654px; /* Width from your image */
    height: 505px; /* Height from your image */
    box-sizing: border-box;
}

.service-title {
    width: 215px;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
}

.service-description {
    width: 510px;
    font-size: 1rem;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    margin-top: 0;
}

.service-content {
    width: 100%;
}

/* Service card styles */
.service-card {
    display: flex;
    flex-direction: row; /* Default direction */
    align-items: center;
    justify-content: flex-start; /* Align to the start of the flex container */
    margin: 20px 0;
    padding: 1%;
    background: #fff;
    box-sizing: border-box; /* Include padding and border in the box's size */
}

@media (max-width: 768px) {
    .services-container {
      grid-template-columns: 1fr; /* One column on small screens */
    }
  }

.service-card .service-image {
    width: 50%; /* Adjust the image size as needed */
    height: 30%;
    margin-right: 20px; /* Space between image and text */
    top: 0;
    left: 0;
}

.service-card.left .service-image {
    order: -1; /* Image will be to the left */
}

.service-card.right .service-image {
    order: 1; /* Image will be to the right */
}

.service-card .service-text {
    flex: 1; /* Allows text to fill the remaining space */
}

.service-text h2 {
    /* Styles for the title (h2 element) */
    font-size: 24px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add margin below the title */
    /* Add any other title-specific styles here */
  }
  
  .service-text p {
    /* Styles for the description (p element) */
    font-size: 16px; /* Adjust font size as needed */
    /* Add any other description-specific styles here */
  }

/* Responsive design adjustments */
@media (max-width: 768px) {
    .header {
        padding: 2rem 1rem; /* Reduced padding for smaller screens */
    }

    .service-card {
        flex-direction: column;
        width: 100%; /* Full width on mobile */
    }

    .service-card .service-image {
        width: 100%; /* Full width for image on mobile */
        margin-bottom: 20px; /* Space between image and text */
    }
}

/* Footer styles */
.footer {
    background-color: #2a3f5f;
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
    font-size: 1rem;
    position: fixed; /* Set the position to fixed */
    bottom: 0; /* Position at the bottom of the screen */
    left: 0;
    right: 0;
    height: 80px; /* Set a fixed height for the footer */
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.footer p {
    margin: 5px 0;
}

.footer .contact-info a {
    color: white; /* Set the color of the link - change as needed */
    text-decoration: none; /* Removes underline */
  }
  
  .footer .contact-info a:hover {
    text-decoration: underline; /* Optional: add underline on hover for better UX */
  }

.social-media-links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.social-media-links a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 15px;
}

.social-media-links a img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #4a5568;
    padding: 10px 0;
    text-align: center;
    margin: 20px 0;
}

h1, p {
    font-family: 'Arial', sans-serif;
}

h1 {
    font-weight: bold;
}

p {
    font-size: 1em;
}

.cta-button {
    padding: 10px 20px;
    background-color: #ff9900;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
}

.contact-us-button {
    padding: 10px 20px;
    background-color: #007bff; /* Button background color */
    color: white; /* Button text color */
    text-decoration: none; /* Remove underline from link */
    border-radius: 5px; /* Rounded corners for the button */
    transition: background-color 0.3s ease; /* Smooth background color transition on hover */
  }
  
  .contact-us-button:hover {
    background-color: #0056b3; /* Darker blue on hover for interactivity */
  }


  /* Responsive design for mobile devices */
@media (max-width: 768px) {
    .footer {
        padding: 30px 10px; /* Increased padding for mobile */
        min-height: 100px; /* Adjust as needed for consistent height */
    }

    .footer .contact-info {
        background-color: #2a3f5f; /* Ensure background color on mobile */
        text-align: center; /* Center-align the text */
    }

    .footer .contact-info a {
        color: white; /* Set the color of the link - change as needed */
        text-decoration: none; /* Removes underline */
    }

    .footer .contact-info a:hover {
        text-decoration: underline; /* Optional: add underline on hover for better UX */
    }

    /* Additional responsive adjustments as needed */
}