.contact-us-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info-page {
  text-align: center;
  padding: 40px 20px;
  background-color: #ffffff; /* Adjust the color to match your design */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Adjust the width as necessary */
  margin: 20px auto;
  font-family: Arial, sans-serif; /* Use the font of your choice */
}

.contact-info-page h2 {
  color: #007bff; /* Your brand blue */
  font-size: 2rem; /* Adjust the size as needed */
  margin-bottom: 1rem; /* Space below the heading */
}

.contact-info-page p {
  color: #333; /* Dark grey for text */
  line-height: 1.4;
  margin-bottom: 1rem; /* Space between lines */
  font-size: 1rem; /* Adjust as needed */
}

.contact-info-page a {
  color: #007bff; /* Your brand blue */
  text-decoration: none; /* No underline */
}

.contact-info-page a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}

.contact-details-page p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.contact-details-page strong {
  display: block;
  color: #333;
  font-size: 1.2rem;
}

.contact-details-page a {
  color: #007bff;
  text-decoration: none;
}

.contact-details-page a:hover {
  text-decoration: underline;
}

.contact-form {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form form input, 
.contact-form form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form form button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
